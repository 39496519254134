import { ADMIN, BASE_USER, CONTRIBUTOR, PRODUCER, REDACTOR, SUPER_ADMIN } from '../interfaces';

export const StatusColors = {
  CREATED: 'green', // draft
  SUBMITTED: 'blue', // sent for review to editor / admin
  REDACTED: 'yellow', // ??? when admin / editor edited my event ???
  PRODUCED: 'orange', // when audio is created and attached by producer
  ONLINE: 'purple', // when approved by admin / editor
  OFFLINE: 'lightgrey', // when set offline by admin / editor / contributor
  REJECTED: 'red',
};
export const EventStatus = {
  CREATED: 'CREATED',
  SUBMITTED: 'SUBMITTED',
  REDACTED: 'REDACTED',
  PRODUCED: 'PRODUCED',
  ONLINE: 'ONLINE',
  OFFLINE: 'OFFLINE',
  REJECTED: 'REJECTED',
};
export const UserRoles = {
  REDACTOR: REDACTOR,
  PRODUCER: PRODUCER,
  SUPER_ADMIN: SUPER_ADMIN,
  ADMIN: ADMIN,
  CONTRIBUTOR: CONTRIBUTOR,
  BASE_USER: BASE_USER,
};

export const AudioLimits = {
  size: 5,
  podcastTime: 20,
  podcastMinTime: 10,
  commentTime: 10,
  errorMargin: 0.5,
};
export const StaticPagesWP = {
  AboutUs: 'https://www.anto.info/qui-sommes-nous/',
  TermsOfPayment: 'https://www.anto.info/cgv',
  TermsOfUse: 'https://www.anto.info/cgu',
  LegalPage: 'https://www.anto.info/mentions-legales',
  Help: 'https://www.anto.info/contactez-nous/',
};
