import { Reducer } from 'react';
import * as Action from './Events.actions';
import { withLoadable } from 'utils/hoc';
import { EventsActionTypes, EventsState } from './Event.interfaces';
import { IDetailedEvent } from '../../interfaces';

const initialState: EventsState = {
  loading: false,
  error: null,
  loggedIn: false,
  eventsList: [],
  detailedEvents: [],
  totalEvents: 0,
  hasMore: false,
  singleEvent: null,
  oneEventForEdit: null,
  contributorEvents: [],
  contributorEventsCount: 0,
  queryParams: {},
  contributorInfo: null,
  favorites: [],
  userData: null,
  playerList: {
    list: [],
    account: [],
    index: 0,
  },
  filteredTags: [],
  duplicateEventId: null,
  phonetics: [],
  eventPrices: [],
};

const reducer: Reducer<EventsState, EventsActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case Action.OPEN_PLAYER: {
      return {
        ...state,
        playerList: action.payload,
        loading: false,
      };
    }
    case Action.DUPLICATE_EVENT: {
      return {
        ...state,
        duplicateEventId: action.payload,
      };
    }
    case Action.CLEAR_DUPLICATE_EVENT: {
      return {
        ...state,
        duplicateEventId: null,
      };
    }
    case Action.CLEAR_ONE_EVENT: {
      return {
        ...state,
        oneEventForEdit: null,
        eventPrices: [],
      };
    }
    case Action.GET_EVENTS_SUCCESS: {
      return {
        ...state,
        eventsList: [...state.eventsList, ...action.payload.results],
        hasMore: !!action.payload.next,
      };
    }
    case Action.GET_DETAILED_EVENTS_SUCCESS: {
      return {
        ...state,
        detailedEvents: [...state.detailedEvents, ...action.payload.results],
        totalEvents: action.payload.count,
        hasMore: !!action.payload.next,
      };
    }
    case Action.DELETE_EVENT_SUCCESS: {
      const updatedEvents = state.detailedEvents.filter((de: IDetailedEvent) => de.id != action.payload.id);
      return {
        ...state,
        totalEvents: state.detailedEvents != updatedEvents ? state.totalEvents - 1 : state.totalEvents,
        detailedEvents: updatedEvents,
      };
    }
    case Action.GET_CONTRIBUTOR_SUCCESS: {
      return {
        ...state,
        contributorInfo: action.payload,
      };
    }
    case Action.GET_CONTRIBUTOR_EVENTS_SUCCESS: {
      return {
        ...state,
        contributorEvents: [...state.contributorEvents, ...action.payload.results],
        contributorEventsCount: action.payload.count,
        hasMore: !!action.payload.next,
      };
    }
    case Action.GET_SINGLE_EVENT_SUCCESS: {
      return {
        ...state,
        singleEvent: action.payload,
      };
    }
    case Action.GET_ONE_EVENT_SUCCESS: {
      return {
        ...state,
        oneEventForEdit: action.payload,
      };
    }
    case Action.SET_EVENT_STATUS_SUCCESS: {
      return {
        ...state,
        oneEventForEdit: action.payload,
      };
    }
    case Action.EVENT_DETAIL_STATUS_UPDATE_SUCCESS: {
      return {
        ...state,
        oneEventForEdit: action.payload,
      };
    }
    case Action.CLEAR_EVENTS: {
      return {
        ...state,
        eventsList: [],
        contributorEvents: [],
        contributorEventsCount: 0,
      };
    }
    case Action.CLEAR_CONTRIBUTOR: {
      return {
        ...state,
        contributorInfo: null,
      };
    }
    case Action.CLEAR_SINGLE_EVENT: {
      return {
        ...state,
        singleEvent: null,
      };
    }
    case Action.GET_USER_DATA_SUCCESS: {
      return {
        ...state,
        userData: action.payload,
        favorites: action.payload.favorites,
      };
    }
    case Action.POST_PHONETIC_SUCCESS: {
      return {
        ...state,
        phonetics: [...state.phonetics.filter((p) => p.id !== action.payload.id), action.payload],
      };
    }
    case Action.LIST_PHONETICS_SUCCESS: {
      return {
        ...state,
        phonetics: action.payload,
      };
    }
    case Action.DELETE_PHONETIC_SUCCESS: {
      return {
        ...state,
        phonetics: state.phonetics.filter((phonetic) => phonetic.id !== action.payload),
      };
    }
    case Action.UPDATE_EVENT_SUCCESS:
    case Action.CREATE_EVENT_SUCCESS: {
      return {
        ...state,
        eventPrices: [],
      };
    }

    case Action.SET_EVENT_PRICES:
    case Action.GENERATE_EVENT_PRICES_SUCCESS: {
      return {
        ...state,
        eventPrices: action.payload,
      };
    }
    default:
      return state;
  }
};

export const eventsReducer = withLoadable([
  Action.GET_EVENTS,
  Action.GET_DETAILED_EVENTS,
  Action.GET_CONTRIBUTOR,
  Action.GET_SINGLE_EVENT,
  Action.GET_ONE_EVENT,
  Action.SET_EVENT_STATUS,
  Action.GET_CONTRIBUTOR_EVENTS,
  Action.GET_USER_DATA,
  Action.POST_COMMENT,
  Action.PATCH_REPORT,
  Action.GET_FAVORITES,
  Action.POST_PHONETIC,
  Action.DELETE_PHONETIC,
  Action.LIST_PHONETICS,
  Action.DELETE_EVENT,
  Action.UPDATE_EVENT,
  Action.CREATE_EVENT,
])(reducer);
