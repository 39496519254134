import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EventsState } from 'modules/Events/Event.interfaces';
import { useTranslation } from 'react-i18next';
import { FiX } from 'react-icons/fi';
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';
import { Button } from 'antd';
import Lottie from 'react-lottie';
import { getUserLanguage, showInfo } from 'utils/helpers';
import { PlayIcon } from 'components/icons/PlayIcon';
import { PauseIcon } from 'components/icons/PauseIcon';
import { handleOpenPlayer } from 'modules/Events/Events.actions';
import {
  AudioWrapper,
  CardWrapper,
  DescriptionCard,
  FLexWrapper,
  ImgWrapper,
  PriceArea,
  TitleArea,
  TranscriptionPlayer,
} from './Player.style';
import Avatar from 'assets/images/avatar.svg';
import ErrorImg from 'assets/images/error.png';
import 'react-h5-audio-player/lib/styles.css';
import { ContainerWrapper, IContainer } from 'assets/styles/Container.style';
import AudioTrack from 'assets/audio/Anto virgule 3.1 (1).mp3';
import { StyledComponent } from 'styled-components';
import { useHistory } from 'react-router-dom';
import defaultTheme from 'assets/styles';
import { BackIcon } from 'components/icons/BackIcon';
import { ForwardIcon } from 'components/icons/ForwardIcon';
import { MdOutlineSubtitles, MdOutlineSubtitlesOff } from 'react-icons/md';
import { GlobalState } from '../../../modules/Global/Global.interfaces';
import { postAudioPlayed, putAudioStopped } from '../../../modules/Statistics/Statistics.actions';
import { StatisticsState } from '../../../modules/Statistics/Statistics.interfaces';
import { ALL_INCLUSIVE, MULTI_CHANNEL } from '../../../interfaces';
import envVariables from '../../../constant';

const audio = new Audio(AudioTrack);

interface ICaption {
  start: number;
  end: number;
  text: string;
}

export const Player = () => {
  const {
    playerList: { list, account, index },
  } = useSelector(({ eventsReducer }: { eventsReducer: EventsState }) => eventsReducer);
  const { audio_played_id } = useSelector(
    ({ statisticsReducer }: { statisticsReducer: StatisticsState }) => statisticsReducer,
  );
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef<StyledComponent<'div', any, IContainer, never>>(null);
  const [ind, setInd] = useState<number>(index);
  const [avatarImg, setAvatarImg] = useState(account[ind]?.avatar || Avatar);
  const [animating, setAnimating] = useState<boolean>(false);
  const [animationHeight, setAnimationHeight] = useState(0);
  const [animation, setAnimation] = useState();
  const [isHidden, setIsHidden] = useState(false);
  const [subtitles, setSubtitles] = useState<ICaption[]>([]);
  const [currentSubtitle, setCurrentSubtitle] = useState<string>('');
  const introDuration = 2.1; // Duration of the intro in seconds
  const [playerCurrentTime, setPlayerCurrentTime] = useState(0);
  const playerCurrentTimeRef = useRef(playerCurrentTime);
  const audioEventIdRef = useRef(audio_played_id);
  const eventIdRef = useRef(account[ind].id);
  const { iframeParams } = useSelector(({ globalReducer }: { globalReducer: GlobalState }) => globalReducer);
  const player = useRef<AudioPlayer>(null);
  const lng = useMemo(() => {
    return getUserLanguage().lng;
  }, []);
  useEffect(() => {
    audioEventIdRef.current = audio_played_id;
  }, [audio_played_id]);

  useEffect(() => {
    if (ref && ref.current) {
      setAnimationHeight(ref.current.clientHeight);
    }
    async function fetchAnimation() {
      const response = await fetch(envVariables.lottieAnimation);
      const anim = await response.json();
      setAnimation(anim);
    }
    fetchAnimation();
    return () => {
      if (audioEventIdRef.current) {
        dispatch(
          putAudioStopped.request({
            event_detail_id: eventIdRef.current,
            id: audioEventIdRef.current,
            duration: playerCurrentTimeRef.current,
          }),
        );
      }
    };
  }, []);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid meet',
    },
    duration: 3000,
  };
  useEffect(() => {
    setIsHidden(false);
    if (list.length && index) {
      setInd(index);
    } else {
      setInd(0);
    }
  }, [list]);

  useEffect(() => {
    eventIdRef.current = account[ind].id;
    if (ind && audioEventIdRef.current) {
      dispatch(
        putAudioStopped.request({
          id: audioEventIdRef.current,
          duration: playerCurrentTimeRef.current,
        }),
      );
    }
    dispatch(postAudioPlayed.request({ event_detail_id: eventIdRef.current }));
  }, [ind]);

  useEffect(() => {
    setSubtitles([]);
    const transcription = account[ind]?.transcription;
    if (transcription) {
      setSubtitles(parseSRT(transcription));
    }
    setAvatarImg(account[ind]?.avatar || Avatar);
  }, [ind, list]);

  useEffect(() => {
    playerCurrentTimeRef.current = playerCurrentTime;
    const currentTime = playerCurrentTime + introDuration;
    const subtitle = subtitles.find((sub) => currentTime >= sub.start && currentTime <= sub.end);
    setCurrentSubtitle(subtitle ? subtitle.text : '');
  }, [playerCurrentTime, subtitles]);
  const parseSRT = (srt: string): ICaption[] => {
    const blocks = srt.split('\n\n');
    return blocks
      .filter((block) => block.includes(' --> '))
      .map((block) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [index, time, ...lines] = block.split('\n');
        const [start, end] = time.split(' --> ');
        return {
          start: convertTimeToSeconds(start),
          end: convertTimeToSeconds(end),
          text: lines.join(' '),
        };
      });
  };

  const convertTimeToSeconds = (time: string) => {
    const [hours, minutes, seconds] = time.split(':');
    const [mainSeconds, ms] = seconds.split(',');
    return parseInt(hours) * 3600 + parseInt(minutes) * 60 + parseInt(mainSeconds) + parseInt(ms) / 1000;
  };

  const onControlClick = (next: boolean) => () => {
    audio.pause();
    dispatch(
      putAudioStopped.request({
        id: audioEventIdRef.current,
        duration: playerCurrentTimeRef.current,
      }),
    );
    setCurrentSubtitle('');
    if (next && ind !== list.length - 1) {
      setInd(ind + 1);
      setAnimating(true);
      const promisePlay = audio.play();
      if (promisePlay !== undefined) {
        promisePlay.then().catch((error) => {
          showInfo({ text: error, title: t('Please allow sound on this page for better experience') });
        });
      }
    } else if (!next && ind !== 0) {
      setInd(ind - 1);
    }
  };

  const handleClose = () => {
    dispatch(handleOpenPlayer.request({ list: [], account: null, index: 0 }));
  };

  return (
    <AudioWrapper style={iframeParams.isIframe ? { bottom: '60px' } : { bottom: '0' }}>
      <ContainerWrapper ref={ref} as={FLexWrapper}>
        {animating && (
          <Lottie
            speed={20}
            options={defaultOptions}
            height={animationHeight}
            width="100%"
            eventListeners={[
              {
                eventName: 'loopComplete',
                callback: () => setAnimating(false),
              },
            ]}
          />
        )}
        {!animating && (
          <>
            <CardWrapper>
              <ImgWrapper
                loading="lazy"
                src={avatarImg}
                onError={() => setAvatarImg(ErrorImg)}
                alt="title"
                draggable={false}
                onClick={() =>
                  account[ind].id && history.push(`/event/${account[ind]?.eventId}/${lng}/${account[ind]?.title}`)
                }
              />
              <DescriptionCard>
                <TitleArea
                  onClick={() =>
                    account[ind].id && history.push(`/event/${account[ind]?.eventId}/${lng}/${account[ind]?.title}`)
                  }
                >
                  {account[ind]?.title || account[ind]?.screen_name}
                </TitleArea>
                {account[ind]?.title && (
                  <PriceArea
                    className="price"
                    onClick={() =>
                      (account[ind]?.plan?.type == ALL_INCLUSIVE || account[ind]?.plan?.type == MULTI_CHANNEL) &&
                      !iframeParams.isIframe &&
                      history.push(`/@${account[ind].alias}`)
                    }
                  >
                    {account[ind]?.screen_name}
                  </PriceArea>
                )}
              </DescriptionCard>
              <Button
                className={'mobile-only'}
                type="text"
                ghost
                style={{ padding: '0', width: '2em', height: '2em' }}
                onClick={handleClose}
              >
                <FiX size="2em" color={defaultTheme.primary[5]} />
              </Button>
            </CardWrapper>
            <AudioPlayer
              autoPlay
              autoPlayAfterSrcChange={true}
              showSkipControls
              showJumpControls={false}
              layout="stacked-reverse"
              listenInterval={50}
              onListen={(e: any) => setPlayerCurrentTime(e.target?.currentTime || 0)}
              customProgressBarSection={[
                <div className="transcription-wrapper">
                  <TranscriptionPlayer hidden={isHidden}>
                    <span>{currentSubtitle}</span>
                  </TranscriptionPlayer>
                  <div className="transcription-toggle">
                    {isHidden ? (
                      <MdOutlineSubtitles
                        size="2em"
                        color={defaultTheme.primary[5]}
                        onClick={() => {
                          setIsHidden(!isHidden);
                        }}
                      />
                    ) : (
                      <MdOutlineSubtitlesOff
                        size="2em"
                        color={defaultTheme.primary[5]}
                        onClick={() => {
                          setIsHidden(!isHidden);
                        }}
                      />
                    )}
                  </div>
                </div>,
                RHAP_UI.CURRENT_TIME,
                RHAP_UI.PROGRESS_BAR,
                RHAP_UI.DURATION,
              ]}
              customControlsSection={[
                RHAP_UI.ADDITIONAL_CONTROLS,
                RHAP_UI.MAIN_CONTROLS,
                RHAP_UI.VOLUME_CONTROLS,
                <Button
                  className={'mobile-hidden'}
                  type="text"
                  ghost
                  style={{ padding: '0', width: '2em', height: '2em' }}
                  onClick={handleClose}
                >
                  <FiX size="2em" color={defaultTheme.primary[5]} />
                </Button>,
              ]}
              customIcons={{
                play: <PlayIcon fill={defaultTheme.primary[5]} />,
                pause: <PauseIcon fill={defaultTheme.primary[5]} />,
                previous: <BackIcon fill={defaultTheme.primary[5]} />,
                next: <ForwardIcon fill={defaultTheme.primary[5]} />,
              }}
              src={list[ind]}
              onEnded={onControlClick(true)}
              onClickPrevious={onControlClick(false)}
              onClickNext={onControlClick(true)}
              ref={player}
            />
          </>
        )}
      </ContainerWrapper>
    </AudioWrapper>
  );
};
