import { axiosInstance } from 'services/api';
import { IComment, IDetailedEvent, IEntity, IEvent, IEventPrice, IFilterEventsParams, IUser } from 'interfaces';
import { axiosInstance as anonAxiosInstance } from 'services/anonApi';

export default {
  async getEventsList(query: IFilterEventsParams) {
    return anonAxiosInstance.get<IEvent[]>('event/', { params: { ...query, serializer: 'flatten' } });
  },
  async getDetailedEvents(query: IFilterEventsParams) {
    return axiosInstance.get<IDetailedEvent[]>('event/', { params: { ...query, serializer: 'detailed' } });
  },
  async getContributor(payload: { alias?: string; id?: number }) {
    if (payload.alias) {
      return axiosInstance.get<IEntity>(`entity/@${payload.alias}/`);
    } else if (payload.id) {
      return axiosInstance.get<IEntity>(`entity/contrib/${payload.id}/`);
    } else {
      throw new Error('Invalid payload. Must include either screen_name or id.');
    }
  },
  async getSingleEvent(payload: { id: number; language: string }) {
    return anonAxiosInstance.get<IEvent>(`event/${payload.id}/`, {
      params: {
        language: payload.language,
        serializer: 'flatten',
      },
    });
  },
  async getOneEvent(id: number) {
    return axiosInstance.get<IDetailedEvent>(`event/${id}/`);
  },
  async createEvent(data: FormData) {
    return axiosInstance.post<IEvent>('event/', data);
  },
  async updateEvent(data: FormData) {
    return axiosInstance.patch(`event/${data.get('id')}/`, data);
  },
  async deleteEvent(id: number) {
    return axiosInstance.delete(`event/${id}/`);
  },
  async rejectEvent(data: { event: IDetailedEvent; rejection_message: string }) {
    return axiosInstance.patch(`event/${data.event.id}/set-event-rejected/`, data);
  },
  async putEventOnline(data: number) {
    return axiosInstance.patch(`event/${data}/set-event-online/`);
  },
  async putEventOffline(data: number) {
    return axiosInstance.patch(`event/${data}/set-event-offline/`);
  },
  async rejectEventDetail(data: { eventDetailId: number; rejection_message: string }) {
    return axiosInstance.patch(`event/${data.eventDetailId}/set-event-detail-rejected/`, data);
  },
  async putEventDetailOnline(data: number) {
    return axiosInstance.patch(`event/${data}/set-event-detail-online/`);
  },
  async putEventDetailOffline(data: number) {
    return axiosInstance.patch(`event/${data}/set-event-detail-offline/`);
  },
  async getContributorEvents(query: IFilterEventsParams) {
    return axiosInstance.get<IEvent[]>('event/', { params: { ...query, serializer: 'flatten' } });
  },
  async postFavorite(data: { id: number }) {
    return axiosInstance.post('favorites/', data);
  },
  async getUserData() {
    return axiosInstance.get<IUser>('current-user/');
  },
  async postComment(data: IComment) {
    return axiosInstance.post('event/comment/', data);
  },
  async deleteComment(id: number) {
    return axiosInstance.delete(`event/comment/${id}/`);
  },
  async patchReport(data: { id: number; reports: string[] }) {
    return axiosInstance.patch(`event/${data.id}/report/`, { reports: data.reports });
  },
  async postPhonetic(data: FormData) {
    return axiosInstance.post('event/phonetic/', data);
  },
  async listPhonetics() {
    return axiosInstance.get('event/phonetic/');
  },
  async deletePhonetic(id: number) {
    return axiosInstance.delete(`event/phonetic/${id}/`);
  },
  async downloadUserEventsCSV(id: number) {
    return axiosInstance.get(`event/csv/export/${id}/`);
  },
  async generateEventPrices(payload: IEventPrice[]) {
    return axiosInstance.post(`event/generate-event-prices/`, payload);
  },
};
